import React from 'react';

import { Box, Flex } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/toast';
import { Form, Formik } from 'formik';
import { useMutation, useQuery } from 'react-query';

import usersApi from 'api/users';
import AppModal from 'components/modules/AppModal';
import Button from 'components/primitives/Button';
import FormikTextField from 'components/primitives/FormikTextField';
import Typography from 'components/primitives/Typography';
import { FETCH_ALL_USER_EXEMPTIONS } from 'constants/queries';
import { ApiResponseError } from 'types/Api';

import { UsersFiltersProps } from './UsersNotifyExemptions.props';

const UsersFiltersView = (props: UsersFiltersProps): JSX.Element => {
  const toast = useToast();
  const { data = [], refetch } = useQuery(FETCH_ALL_USER_EXEMPTIONS, () =>
    usersApi.getAllNotifyExemptions()
  );

  const { mutate: exempt, isLoading } = useMutation(
    usersApi.doAddNotifyExemption,
    {
      onSuccess: () => {
        refetch();
        toast({
          description: `Added exemption`,
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
      },

      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Error',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  const { mutate: removeExempt } = useMutation(
    usersApi.doRemoveNotifyExemption,
    {
      onSuccess: () => {
        refetch();
        toast({
          description: `Removed exemption`,
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Error',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  return (
    <AppModal {...props} size="xl" title="Exemptions">
      <Formik
        initialValues={{ email: '', phone: '' }}
        onSubmit={(values, { resetForm }) => {
          exempt({
            email: values.email,
            phone: `64${Number(values.phone)}`,
          });
          resetForm();
        }}
      >
        <Form>
          <Flex>
            <Flex flexDirection="column" flex={1}>
              <FormikTextField fieldName="email" label="Email" />
            </Flex>
            <Box w={10} />
            <Flex flexDirection="column" flex={1}>
              <FormikTextField
                width={200}
                LeftComponent={
                  <Flex mr={1}>
                    <Typography variant="type8" color="shade3">
                      +64
                    </Typography>
                  </Flex>
                }
                fieldName="phone"
                label="Phone"
              />
            </Flex>
          </Flex>
          <Flex flexDirection="column" alignItems="flex-end">
            <Flex flexDirection="column" width={100}>
              <Button
                type="submit"
                variant="small"
                disabled={isLoading}
                loading={isLoading}
              >
                Add
              </Button>
            </Flex>
          </Flex>
        </Form>
      </Formik>
      <Flex borderBottom="1px solid #ddd" flex={1} py={2} px={6} mx={-6}>
        <Typography weight="600" color="shade5">
          Exemptions for Email & SMS Notifications
        </Typography>
      </Flex>
      <Flex mt={1} flexDirection="column">
        {data.map((ex, i) => (
          <Flex
            key={ex.id}
            flex={1}
            py={3}
            mx={-6}
            px={6}
            alignItems="center"
            bgColor={i % 2 === 0 ? 'transparent' : '#F2F5FF'}
          >
            <Flex flex={1}>
              <Typography variant="type8" color="shade5">
                {ex.email}
              </Typography>
            </Flex>
            <Flex flex={1}>
              <Typography variant="type8" color="shade5">
                {ex.phone}
              </Typography>
            </Flex>
            <Button outline variant="tiny" onClick={() => removeExempt(ex.id)}>
              Remove
            </Button>
          </Flex>
        ))}
      </Flex>
    </AppModal>
  );
};

export default UsersFiltersView;
