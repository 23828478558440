import api from 'api/instance';
import {
  BuyPropertyStatus,
  MarketingMethod,
  PropertyTypes,
  SellPropertyStatus,
} from 'constants/property';
import {
  BuyerAdvisory,
  BuyListingSummary,
  Listing,
  Match,
  OpenHomeDetails,
  PreRegActivity,
  PropertyDraftItem,
  PropertyLocation,
  SellListingSummary,
} from 'types/Property';

const URL = `${process.env.REACT_APP_API_URL}/admin/properties`;

export type GetAllPropertiesResponse = {
  data: (Listing & { isSellerLessAgentManaged: boolean })[];
  meta: {
    count: number;
    page: number;
    limit: number;
  };
};

const getAll = async (payload?: string): Promise<GetAllPropertiesResponse> => {
  const { data } = await api.get<GetAllPropertiesResponse>(`${URL}${payload}`);
  return data;
};

export type GetListingResponse = {
  data: SellListingSummary | BuyListingSummary;
};

const getListing = async (
  id: string
): Promise<SellListingSummary | BuyListingSummary> => {
  const { data } = await api.get<GetListingResponse>(`${URL}/${id}`);
  return data.data;
};

const getListingMatches = async (id: string): Promise<Match[]> => {
  const { data } = await api.get<{ data: Match[] }>(`${URL}/${id}/matches`);
  return data.data;
};

const getOpenHomeDetails = async (id: string): Promise<OpenHomeDetails[]> => {
  const { data } = await api.get<{ data: OpenHomeDetails[] }>(
    `${URL}/${id}/openHome`
  );
  return data.data;
};

const getBuyerAdvisory = async (id: string): Promise<BuyerAdvisory[]> => {
  const { data } = await api.get<{ data: BuyerAdvisory[] }>(
    `${URL}/${id}/sellerAdvisories`
  );
  return data.data;
};

type AdActivities = {
  addedToWatchList: any[];
  enteredEmail: any[];
  inquiries: any[];
  pageEvents: any;
};

const getAdActivities = async (id: string): Promise<AdActivities> => {
  const { data } = await api.get<{ data: AdActivities }>(
    `${URL}/${id}/adActivities`
  );
  return data.data;
};

const getListingPreregActivities = async (
  id: string
): Promise<PreRegActivity[]> => {
  const { data } = await api.get<{ data: PreRegActivity[] }>(
    `${URL}/${id}/preRegistrationActivities`
  );
  return data.data;
};

const doLock = async (id: string): Promise<any> => {
  const { data } = await api.delete<any>(`${URL}/${id}/unlock`);
  return data;
};

const doUnlock = async (id: string): Promise<any> => {
  const { data } = await api.post<any>(`${URL}/${id}/unlock`);
  return data;
};

const doVerify = async (id: string): Promise<any> => {
  const { data } = await api.post<any>(`${URL}/${id}/verify`);
  return data;
};

const doUnverify = async (id: string): Promise<any> => {
  const { data } = await api.delete<any>(`${URL}/${id}/verify`);
  return data;
};

export type SellDetailsRequest = {
  street: string;
  location: PropertyLocation;
  propertyType: string;
  landArea?: number;
  landUnit: string;
  marketPrice: string;
  displayPrice?: string;
  marketingMethod?: MarketingMethod;
  preferredPrice?: number;
  closingDateTime?: string;
  unlessSoldPrior?: boolean;
  status: SellPropertyStatus;
  isPrivate: boolean;
  bedrooms: number;
  garage: number;
  shortId: string;
  fencing?: string;
  otherFeatures?: string[];
  description?: string;
  heading?: string;
  livingAreas?: number;
  bathroom?: number;
  toilets?: number;
  glazing?: string;
  images?: string[];
  mainImage?: string;
  heating?: string[];
};

const doUpdateSellProperty = async (
  id: string,
  values: Partial<SellDetailsRequest>
): Promise<any> => {
  const { data } = await api.post<any>(`${URL}/sell/${id}`, values);
  return data;
};

export type BuyDetailsRequest = {
  propertyType: PropertyTypes;
  location: PropertyLocation;
  landArea: { from?: number | null; to?: number | null };
  bedrooms: { from?: number | null; to?: number | null };
  garage: { from?: number | null; to?: number | null };
  noOfResidents: string;
  petsAnimals: string;
  parkingSpace: string;
  specificRequirement: string;
  marketPrice: string | number;
  status: BuyPropertyStatus;
};

const doUpdateBuyProperty = async (
  id: string,
  values: Partial<BuyDetailsRequest>
): Promise<any> => {
  const { data } = await api.post<any>(`${URL}/buy/${id}`, values);
  return data;
};

export type GetAllPropertyDraftsResponse = {
  data: PropertyDraftItem[];
  meta: {
    count: number;
    page: number;
    limit: number;
  };
};

const getDrafts = async (
  payload?: string
): Promise<GetAllPropertyDraftsResponse> => {
  const { data } = await api.get<GetAllPropertyDraftsResponse>(
    `${URL}/draft${payload}`
  );
  return data;
};

const getDraft = async (id: string): Promise<SellListingSummary> => {
  const { data } = await api.get<{ data: SellListingSummary }>(
    `${URL}/draft/${id}`
  );
  return data.data;
};

export type DraftDetailsRequest = {
  street: string;
  location: PropertyLocation;
  propertyType: string;
  landArea?: number;
  landUnit: string;
  marketPrice: string;
  displayPrice?: string;
  marketingMethod?: MarketingMethod;
  preferredPrice?: number;
  closingDateTime?: string;
  unlessSoldPrior?: boolean;
  isPrivate: boolean;
  bedrooms: number;
  garage: number;
  shortId: string;
  fencing?: string;
  otherFeatures?: string[];
  description?: string;
  heading?: string;
  livingAreas?: number;
  bathroom?: number;
  toilets?: number;
  glazing?: string;
  images?: string[];
  mainImage?: string;
  heating?: string[];
};

const doCreateDraft = async (
  values: Partial<DraftDetailsRequest>
): Promise<any> => {
  const { data } = await api.post<any>(`${URL}/draft`, values);
  return data;
};

const doDeleteDraft = async (id: string): Promise<any> => {
  const { data } = await api.delete<any>(`${URL}/draft/${id}`);
  return data;
};

const doUpdateDraft = async (
  id: string,
  values: Partial<DraftDetailsRequest>
): Promise<any> => {
  const { data } = await api.post<any>(`${URL}/draft/${id}`, values);
  return data;
};

export type ClaimDraftEmailRequest = {
  email: string;
  userType: 'agent' | 'seller';
  voucherCode?: string;
};

const doSendClaimDraftEmail = async (
  id: string,
  values: ClaimDraftEmailRequest
): Promise<any> => {
  const { data } = await api.post<any>(`${URL}/draft/${id}/sendEmail`, values);
  return data;
};

export default {
  getAll,
  getListing,
  doLock,
  doUnlock,
  doVerify,
  doUnverify,
  doUpdateSellProperty,
  doUpdateBuyProperty,
  getListingMatches,
  getListingPreregActivities,
  getOpenHomeDetails,
  getBuyerAdvisory,
  getAdActivities,
  getDrafts,
  getDraft,
  doCreateDraft,
  doDeleteDraft,
  doUpdateDraft,
  doSendClaimDraftEmail,
};
