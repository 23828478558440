import React, { useMemo, useState } from 'react';

import { ArrowBackIcon, ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import agentsApi from 'api/agents';
import AgentFullDetailsView from 'components/modules/AgentFullDetails';
import AppModal from 'components/modules/AppModal';
import Button from 'components/primitives/Button';
import { Caret } from 'components/primitives/SVG';
import { FETCH_AGENT_DETAILS } from 'constants/queries';

import AgentDetailsEdit from '../AgentDetailsEdit';
import Notes from '../Notes';
import { AgentDetailsProps, MODE } from './AgentDetails.props';

const AgentDetailsView = ({ id, ...props }: AgentDetailsProps): JSX.Element => {
  const [notesOpen, setNotesOpen] = useState(false);
  const [mode, setMode] = useState(MODE.DEFAULT);
  const history = useHistory();
  const { data: fullDetails, refetch } = useQuery(
    `${FETCH_AGENT_DETAILS}${id}`,
    () => (id ? agentsApi.getDetails(id) : undefined)
  );

  const Content = useMemo(() => {
    if (!fullDetails) return <Flex />;
    if (mode === MODE.EDIT) {
      return (
        <AgentDetailsEdit
          data={fullDetails}
          refetch={refetch}
          onBack={() => setMode(MODE.DEFAULT)}
        />
      );
    }
    return (
      <AgentFullDetailsView
        data={fullDetails}
        refetch={refetch}
        onSetMode={setMode}
      />
    );
  }, [fullDetails, mode]);

  return (
    <AppModal
      {...props}
      size="5xl"
      onClose={() => {
        props.onClose();
      }}
      HeaderRight={
        <>
          <Flex
            position="absolute"
            top={2}
            right={14}
            zIndex={200}
            alignItems="center"
          >
            <Button
              variant="small"
              outline
              onClick={() => setNotesOpen(true)}
              RightComponent={
                <Flex ml={1}>
                  <Caret color="primary" />
                </Flex>
              }
            >
              Notes
            </Button>
            <Flex zIndex={200} ml={3} cursor="pointer" onClick={history.goBack}>
              <ArrowBackIcon />
            </Flex>
          </Flex>
          {id && notesOpen && (
            <Notes id={id} type="agent" onClose={() => setNotesOpen(false)} />
          )}
        </>
      }
      onOverlayClick={props.onClose}
      title={
        fullDetails ? `${fullDetails?.firstName} ${fullDetails?.lastName}` : ''
      }
    >
      {!props.disableNext && (
        <Flex
          position="absolute"
          right="-150px"
          top="40vh"
          width="60px"
          height="60px"
          borderRadius="30px"
          zIndex="100"
          backgroundColor="#fff"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          onClick={props.onNext}
        >
          <ArrowRightIcon />
        </Flex>
      )}
      {!props.disablePrev && (
        <Flex
          position="absolute"
          left="-150px"
          top="40vh"
          width="60px"
          height="60px"
          borderRadius="30px"
          zIndex="100"
          backgroundColor="#fff"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          onClick={props.onPrev}
        >
          <ArrowLeftIcon />
        </Flex>
      )}
      {Content}
    </AppModal>
  );
};

export default AgentDetailsView;
