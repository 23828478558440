import React from 'react';

import { SVGComponentProps } from './SVG.props';

const Brokers = (props: SVGComponentProps): JSX.Element => {
  const { width, height } = props;
  return (
    <svg
      width={width || '20'}
      height={height || '20.5'}
      viewBox={`0 0 20 20.5`}
    >
      <g id="MB-Icon-SideMenu" transform="translate(-29 -128)">
        <rect
          id="IconBounds"
          width="20"
          height="20"
          transform="translate(29 128)"
          fill="none"
        />
        <g
          id="MB-Graphics"
          transform="translate(-608 -204.409)"
          clipPath="url(#clip-path)"
        >
          <g
            transform="matrix(1, 0, 0, 1, 637, 332.41)"
            filter="url(#Graphics)"
          >
            <path
              id="Graphics-2"
              data-name="Graphics"
              d="M19.518,18.4H13.532c-.595,0-1.219-.06-1.813-.12l-3.866-.6a1.714,1.714,0,0,1-.417-.06,1.948,1.948,0,0,1-.981-.507L1.964,13.568a.886.886,0,0,1-.149-1.193c.536-.716,1.071-.716,2.379-.031l.328.2V7.337H3.332a.26.26,0,0,1-.267-.179.338.338,0,0,1,.089-.328L8.507,2a2.359,2.359,0,0,1,3.152,0L16.982,6.86a.336.336,0,0,1,.089.328.3.3,0,0,1-.267.179H15.584v3.97a33.693,33.693,0,0,1,3.934.829v.717A26.51,26.51,0,0,0,15.35,11.9a.216.216,0,0,1-.122-.005,1.971,1.971,0,0,0-.714.011.986.986,0,0,0-.328.168,3.44,3.44,0,0,1-2.052.656h-1.16a2,2,0,0,0-1.695.895.75.75,0,0,0-.128.324.111.111,0,0,0,.009.034c.059.119.356.239.862.239a.289.289,0,0,1,.178.059h2.677c.922,0,1.487.447,1.487,1.193a.3.3,0,1,1-.594,0c0-.149,0-.6-.893-.6H9.815a8.319,8.319,0,0,1-3.39-.357l-2.558-1.58c-1.249-.626-1.368-.477-1.606-.12a.282.282,0,0,0,.059.358L6.841,16.7l.03.03a1.246,1.246,0,0,0,.654.329h.03a1.228,1.228,0,0,0,.328.03l3.926.6a11.213,11.213,0,0,0,1.724.119h5.986v.6ZM8.923,2.446,4.164,6.77h.715a.281.281,0,0,1,.3.3v5.884l1.576.974a4.558,4.558,0,0,0,1.859.268A1,1,0,0,1,8.8,13.21a2.606,2.606,0,0,1,2.141-1.1h1.16a2.8,2.8,0,0,0,1.7-.536,1.749,1.749,0,0,1,1.189-.328V7.068a.282.282,0,0,1,.3-.3h.743L11.273,2.446A1.8,1.8,0,0,0,10.086,2,1.721,1.721,0,0,0,8.923,2.446ZM19.518,17.8v0ZM12.773,10.7,7.42,5.33a.295.295,0,0,1,.417-.418l5.353,5.367a.289.289,0,0,1,0,.418.288.288,0,0,1-.417,0Zm-5.441-1.1A1.189,1.189,0,1,1,8.52,10.786,1.194,1.194,0,0,1,7.331,9.593Zm.595,0A.594.594,0,1,0,8.52,9,.6.6,0,0,0,7.926,9.593ZM10.9,6.016a1.19,1.19,0,1,1,1.19,1.193A1.195,1.195,0,0,1,10.9,6.016Zm.595,0a.595.595,0,1,0,.595-.6A.6.6,0,0,0,11.495,6.016Z"
              transform="translate(0.13 0.5)"
              fill="#1c0066"
              stroke="#1c0066"
              strokeWidth="0.4"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Brokers;
