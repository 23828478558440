import React from 'react';

import { useTheme } from 'utils/theme';

import { SVGComponentProps } from './SVG.props';

const Delete = (props: SVGComponentProps): JSX.Element => {
  const theme = useTheme();
  const { width, height, color } = props;

  return (
    <svg width={width || '20'} height={height || '20'} viewBox="0 0 20 20">
      <path
        data-name="Path 1536"
        d="M10 0a10 10 0 1010 10A10.034 10.034 0 0010 0z"
        fill={color ? theme.colors[color] : '#cc3000'}
      />
      <path
        data-name="Path 1538"
        d="M11.653 10l3.316 3.316-1.652 1.652-3.316-3.316-3.316 3.316-1.652-1.652L8.349 10 5.033 6.684l1.652-1.652 3.316 3.316 3.316-3.316 1.652 1.652z"
        fill="#ffffff"
      />
    </svg>
  );
};

export default Delete;
