import React, { useEffect } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { Switch, useHistory, useLocation } from 'react-router-dom';

import RouteWithSubRoutes from 'components/core/RouteWithSubRoutes';
import Sidebar from 'components/core/Sidebar';
import TopSettings from 'components/core/TopSettings';
// IMPORT ROUTE
import ROUTES, { routesSettings } from 'constants/routes';
import AgentApplications from 'routes/main/AgentApplications';
import Brokers from 'routes/main/Brokers';
import Charities from 'routes/main/Charities';
import Listings from 'routes/main/Listings';
import Users from 'routes/main/Users';

const routeConfig = Object.freeze({
  // MAIN ROUTE ENTRY
  // [ROUTES.MAIN_DRAFTS]: {
  //   component: Drafts,
  //   path: routesSettings.MAIN_DRAFTS.path,
  // },
  // [ROUTES.MAIN_VOUCHERS]: {
  //   component: Vouchers,
  //   path: routesSettings.MAIN_VOUCHERS.path,
  // },
  [ROUTES.MAIN_USERS]: {
    component: Users,
    path: routesSettings.MAIN_USERS.path,
  },
  [ROUTES.MAIN_LISTINGS]: {
    component: Listings,
    path: routesSettings.MAIN_LISTINGS.path,
  },
  [ROUTES.MAIN_AGENT_APPLICATIONS]: {
    component: AgentApplications,
    path: routesSettings.MAIN_AGENT_APPLICATIONS.path,
  },
  [ROUTES.MAIN_CHARITIES]: {
    component: Charities,
    path: routesSettings.MAIN_CHARITIES.path,
  },
  [ROUTES.MAIN_BROKERS]: {
    component: Brokers,
    path: routesSettings.MAIN_BROKERS.path,
  },
});

const MainRoutes = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();

  const { pathname } = location;

  useEffect(() => {
    if (pathname === '/') history.replace(routesSettings.MAIN_LISTINGS.path);
  }, [pathname]);

  return (
    <Flex flex={1} bgColor="background3" minHeight="100vh">
      <Sidebar />
      <Box maxWidth="1400px" width="100%" marginX="auto" px={30} my={5}>
        <TopSettings />
        <Switch>
          {Object.entries(routeConfig).map(([key, val]) => (
            <RouteWithSubRoutes key={key} {...val} />
          ))}
        </Switch>
      </Box>
    </Flex>
  );
};

export default MainRoutes;
