import React from 'react';

import { useTheme } from 'utils/theme';

import { SVGComponentProps } from './SVG.props';

const Clock = (props: SVGComponentProps): JSX.Element => {
  const theme = useTheme();
  const { width, height, color } = props;

  return (
    <svg
      id="clock"
      width={width || '14'}
      height={height || '14'}
      viewBox="0 0 14 14"
    >
      <g id="Group_1475" data-name="Group 1475">
        <g id="Group_1474" data-name="Group 1474">
          <path
            id="Path_1608"
            data-name="Path 1608"
            d="M7,0a7,7,0,1,0,7,7A7,7,0,0,0,7,0ZM7,13a6,6,0,1,1,6-6A6,6,0,0,1,7,13Z"
            fill={theme.colors[color || 'shade1']}
          />
        </g>
      </g>
      <g id="Group_1477" data-name="Group 1477" transform="translate(2.5 2.5)">
        <g id="Group_1476" data-name="Group 1476">
          <path
            id="Path_1609"
            data-name="Path 1609"
            d="M89.833,85.333a.5.5,0,0,0-.5.5v3.5h-3.5a.5.5,0,1,0,0,1h4a.5.5,0,0,0,.5-.5v-4A.5.5,0,0,0,89.833,85.333Z"
            transform="translate(-85.333 -85.333)"
            fill={theme.colors[color || 'shade1']}
          />
        </g>
      </g>
    </svg>
  );
};

export default Clock;
