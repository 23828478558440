import React, { useEffect, useMemo, useState } from 'react';

import { format, parseISO } from 'date-fns';
import QueryString from 'qs';
import { omit } from 'ramda';
import { useQuery, useQueryClient } from 'react-query';
import { useAsyncDebounce } from 'react-table';

import brokersApi from 'api/brokers';
import { FETCH_BROKERS, FETCH_LISTINGS } from 'constants/queries';
import { Broker } from 'types/Broker';

import { BrokersProps } from './Brokers.props';
import BrokersView from './Brokers.view';

export const TABLE_OPTIONS = {
  page: 1,
  limit: 20,
};

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const BrokersContainer = (props: BrokersProps): JSX.Element => {
  const [tableOptions, setTableOptions] =
    useState<Record<string, any>>(TABLE_OPTIONS);

  const queryClient = useQueryClient();

  const { data, refetch, isFetching } = useQuery(FETCH_BROKERS, async () =>
    brokersApi.getAll(
      `${
        props.location.search ? props.location.search + '&' : '?'
      }${QueryString.stringify(tableOptions)}`
    )
  );

  const queries = useMemo(
    () => QueryString.parse(new URLSearchParams(location.search).toString()),
    [location.search]
  );

  useEffect(() => {
    setTableOptions((opts) => ({ ...opts, page: 1 }));
  }, [queries.search]);

  const onSearchKey = useAsyncDebounce(
    (searchKey: string) =>
      props.history.push({
        pathname: props.location.pathname,
        search:
          '?' +
          new URLSearchParams({
            ...omit(['search'], queries),
            ...(searchKey ? { search: searchKey } : {}),
          }).toString(),
      }),
    600
  );

  const setListing = (broker?: Broker) =>
    props.history.push({
      pathname: props.location.pathname,
      search:
        '?' +
        new URLSearchParams({
          ...omit(['id'], queries),
          ...(broker ? { id: broker.id } : {}),
        }).toString(),
    });

  const brokers = React.useMemo(
    () =>
      (data?.data || []).map((broker) => ({
        ...broker,
        name: `${broker.firstName} ${broker.lastName}`,
        createdAt: format(parseISO(broker.createdAt), 'dd/MM/yyyy H:mm a'),
        status: capitalize(broker.status),
      })),
    [data]
  );

  const meta = React.useMemo(
    () =>
      data?.meta || {
        count: 0,
        limit: 10,
        page: 1,
      },
    [data]
  );

  const onSetFilter = (value: string, key: string) =>
    props.history.push({
      pathname: props.location.pathname,
      search: '?' + new URLSearchParams({ [key]: value }).toString(),
    });

  const onClearFilter = () =>
    props.history.push({
      pathname: props.location.pathname,
    });

  useEffect(() => {
    queryClient.cancelQueries([FETCH_LISTINGS]);
    setTimeout(refetch, 300);
  }, [props.location.search, tableOptions]);

  return (
    <BrokersView
      setListing={setListing}
      data={brokers}
      meta={meta}
      onSearchKey={onSearchKey}
      isLoading={isFetching}
      onClearFilter={onClearFilter}
      onSetFilter={onSetFilter}
      tableOptions={tableOptions}
      setTableOptions={setTableOptions}
      refetch={refetch}
    />
  );
};

export default BrokersContainer;
