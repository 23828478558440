import React, { useRef } from 'react';

import { Flex, HStack, useToast } from '@chakra-ui/react';
import { Form, Formik, FormikProps } from 'formik';
import { useMutation, useQuery } from 'react-query';

import brokers from 'api/brokers';
import AppButton from 'components/primitives/Button';
import FormikSelect from 'components/primitives/FormikSelect';
import FormikTextField from 'components/primitives/FormikTextField';
import Typography from 'components/primitives/Typography';
import { FETCH_MORTGAGE_COMPANIES } from 'constants/queries';
import { ApiResponseError } from 'types/Api';

import AppModal from '../AppModal';
import { AddForm, BrokersAddNewProps } from './BrokersAddNew.props';
import { Schema } from './BrokersAddNew.utils';

const BrokersAddNewView = (props: BrokersAddNewProps): JSX.Element => {
  const toast = useToast();
  const formRef = useRef<FormikProps<any>>(null);

  const { data: companies } = useQuery(
    FETCH_MORTGAGE_COMPANIES,
    brokers.getAllCompanies
  );

  const { isLoading, mutate } = useMutation(
    (fields: AddForm) => brokers.doCreate(fields as any),
    {
      onSuccess: () => {
        toast({
          description: 'Broker created successfully.',
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
        props.onClose();
        props.refetch();
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Error',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  return (
    <AppModal {...props} size="sm" title="CREATE NEW MORTGAGE BROKER">
      <Formik<AddForm>
        innerRef={formRef}
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          phoneCode: '64',
          countryCode: 'NZ',
          company: '',
        }}
        // validateOnChange={false}
        validationSchema={Schema}
        onSubmit={(fields) => mutate(fields)}
        validate={console.log}
      >
        <Form>
          <Flex flexDirection="column" p={2}>
            <Flex mt={2} maxW="350px" flexDir="column">
              <FormikTextField fieldName="firstName" label="FIRST NAME" />
              <FormikTextField fieldName="lastName" label="LAST NAME" />
              <FormikTextField
                fieldName="phone"
                label="PHONE NUMBER"
                LeftComponent={
                  <Flex mr={1}>
                    <Typography variant="type8" color="shade1">
                      +64
                    </Typography>
                  </Flex>
                }
              />
              <FormikTextField fieldName="email" label="EMAIL" />

              <FormikSelect
                label="COMPANY"
                fieldName="company"
                options={companies?.map((c) => ({ value: c, label: c })) || []}
              />
            </Flex>
          </Flex>
          <Typography variant="type9" color="shade1">
            Creating this profile will trigger an email with a link for the user
            to access their account.
          </Typography>
          <Flex justifyContent="flex-end" mt={5}>
            <HStack spacing={5}>
              <AppButton
                type="submit"
                loading={isLoading}
                disabled={isLoading}
                variant="small"
              >
                Create
              </AppButton>
              <AppButton
                onClick={props.onClose}
                kind="secondary"
                variant="small"
                disabled={isLoading}
              >
                Cancel
              </AppButton>
            </HStack>
          </Flex>
        </Form>
      </Formik>
    </AppModal>
  );
};

export default BrokersAddNewView;
