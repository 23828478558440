import React from 'react';

import { Button } from '@chakra-ui/button';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Image } from '@chakra-ui/image';
import { Flex, HStack } from '@chakra-ui/layout';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { Box } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import { format } from 'date-fns/esm';
import { useStore } from 'effector-react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';

import agentsApi from 'api/agents';
import usersApi from 'api/users';
import { AgentFullDetailsProps } from 'components/modules/AgentFullDetails/AgentFullDetails.props';
import AvatarView from 'components/primitives/Avatar';
import AppButton from 'components/primitives/Button';
import Label from 'components/primitives/Label';
import Typography from 'components/primitives/Typography';
import { routesSettings } from 'constants/routes';
import { SessionStore } from 'effector/session/store';
import { ApiResponseError } from 'types/Api';
import { getOrdinal } from 'utils/string/getOrdinal';
import ucFirst from 'utils/string/ucFirst';
import { useTheme } from 'utils/theme';

import { MODE } from '../AgentDetails/AgentDetails.props';

const AgentFullDetailsView = (props: AgentFullDetailsProps): JSX.Element => {
  const theme = useTheme();
  const toast = useToast();
  const history = useHistory();
  const sessionStore = useStore(SessionStore);
  const { data } = props;

  const { mutate: doRespond } = useMutation(
    (args: { status: 'APPROVED' | 'REJECTED' }) =>
      agentsApi.doRespond(data.id, {
        ...args,
        updatedBy: sessionStore?.session?.id || '',
      }),
    {
      onSuccess: (data, variables) => {
        props.refetch();
        toast({
          description: `Agent ${
            variables.status === 'APPROVED' ? 'approved' : 'declined'
          }`,
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Error',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  const { mutate: doSuspend } = useMutation(
    () => agentsApi.doSuspend(data.id),
    {
      onSuccess: () => {
        props.refetch();
        toast({
          description: 'Agent is now suspended',
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Error',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  const { mutate: verifyEmail } = useMutation(
    () => usersApi.doVerifyEmail(props.data.id),
    {
      onSuccess: () => {
        props.refetch();
        toast({
          description: `Agent's email has been verified.`,
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Request failed',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  const { mutate: undoVerifyEmail } = useMutation(
    () => usersApi.doUndoVerifyEmail(props.data.id),
    {
      onSuccess: () => {
        props.refetch();
        toast({
          description: `Agent's email set to unverified.`,
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Request failed',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  const onViewUserProfile = () => {
    history.push(`${routesSettings.MAIN_USERS.path}?id=${data.id}`);
  };

  if (!data) return <Flex />;

  const isPending = data.status === ('PENDING' as any);
  const isApproved = data.status === ('APPROVED' as any);
  const isDenied = data.status === ('DENIED' as any);
  const isSuspended = data.status === ('SUSPENDED' as any);

  return (
    <Flex flexDirection="column">
      <HStack spacing={3} alignItems="flex-start">
        <Flex
          flexDir="column"
          border="1px solid #ddd"
          p={4}
          flex={4}
          height="100%"
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid #ddd"
            m={-4}
            px={4}
            py={2}
            mb={4}
          >
            <Typography variant="type8" color="shade5" weight="600">
              AGENT DETAILS
            </Typography>
            <Flex alignItems="center">
              {isSuspended && (
                <Flex mr={2} height="30px">
                  <Label
                    typographyProps={{
                      variant: 'type11',
                      color: 'secondary',
                      style: {
                        height: 10,
                      },
                    }}
                    style={{
                      backgroundColor: '#936CFC',
                    }}
                  >
                    SUSPENDED
                  </Label>
                </Flex>
              )}
              <Flex ml={2}>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon color={theme.colors.primary} />}
                    border={`1px solid ${theme.colors.primary}`}
                    backgroundColor="#fff"
                  >
                    <Typography color="primary" weight="600">
                      Actions
                    </Typography>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() =>
                        isSuspended
                          ? doRespond({
                              status: 'APPROVED',
                            })
                          : doSuspend()
                      }
                    >
                      {isSuspended ? 'Undo Suspend' : 'Suspend'}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        data.emailVerified ? undoVerifyEmail() : verifyEmail()
                      }
                    >
                      {data.emailVerified ? 'Unverify Email' : 'Verify Email'}
                    </MenuItem>
                    <MenuItem onClick={() => props.onSetMode(MODE.EDIT)}>
                      Edit
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </Flex>
          </Flex>
          <Flex>
            <Box ml={3} mr={10}>
              <AvatarView size={80} src={data.profilePic?.medium || ''} />
            </Box>
            <Flex flex={1} flexDirection="column">
              <Flex flex={1}>
                <Flex flexDirection="column" flex={1}>
                  <Typography variant="type10" color="shade1" weight="600">
                    FIRST NAME
                  </Typography>
                  <Typography variant="type8" color="shade5">
                    {data.firstName}
                  </Typography>
                </Flex>
                <Flex flexDirection="column" flex={1}>
                  <Typography variant="type10" color="shade1" weight="600">
                    LAST NAME
                  </Typography>
                  <Typography variant="type8" color="shade5">
                    {data.lastName}
                  </Typography>
                </Flex>
              </Flex>
              <Flex mt={8} flexDirection="column" flex={1}>
                <Typography variant="type10" color="shade1" weight="600">
                  EMAIL ADDRESS
                </Typography>
                <Typography variant="type8" color="shade5">
                  {data.emailAddress}
                </Typography>
              </Flex>
              <Flex mt={8} flexDirection="column" flex={1}>
                <Typography variant="type10" color="shade1" weight="600">
                  PHONE NUMBER
                </Typography>
                <Typography variant="type8" color="shade5">
                  +{data.phoneCode}
                  {data.phone}
                </Typography>
              </Flex>
              <Flex mt={8} flex={1}>
                <Flex flexDirection="column" flex={1}>
                  <Typography variant="type10" color="shade1" weight="600">
                    LICENSE NUMBER
                  </Typography>
                  <Typography variant="type8" color="shade5">
                    {data.licenseNumber}
                  </Typography>
                </Flex>
                <Flex flexDirection="column" flex={1}>
                  <Typography variant="type10" color="shade1" weight="600">
                    REGION
                  </Typography>
                  <Typography variant="type8" color="shade5">
                    {ucFirst(data.region)}
                  </Typography>
                </Flex>
              </Flex>
              <Flex mt={8} flex={1}>
                <Flex flexDirection="column" flex={1}>
                  <Typography variant="type10" color="shade1" weight="600">
                    COMPANY
                  </Typography>
                  <Typography variant="type8" color="shade5">
                    {data.company}
                  </Typography>
                </Flex>
                <Flex flexDirection="column" flex={1}>
                  <Typography variant="type10" color="shade1" weight="600">
                    TRADING AS
                  </Typography>
                  <Typography variant="type8" color="shade5">
                    {data.tradingAs}
                  </Typography>
                </Flex>
              </Flex>
              <Flex mt={8} flexDirection="column" flex={1}>
                <Flex flexDirection="column" flex={1}>
                  <Typography variant="type10" color="shade1" weight="600">
                    COMPANY LOCATION
                  </Typography>
                  <Typography variant="type8" color="shade5">
                    {data.companyLocation}
                  </Typography>
                </Flex>
              </Flex>
              <Flex mt={8} flexDirection="column" flex={1}>
                <Typography variant="type10" color="shade1" weight="600">
                  SERVICES OFFERED
                </Typography>
                {data.speciality.map(({ label }, index) => (
                  <Typography key={label} variant="type8" color="shade5">
                    {getOrdinal(index + 1)} - {label}
                  </Typography>
                ))}
              </Flex>
              <Flex mt={8} flexDirection="column" flex={1}>
                <Typography variant="type10" color="shade1" weight="600">
                  ABOUT
                </Typography>
                <Typography variant="type8" color="shade5">
                  {data.about}
                </Typography>
              </Flex>
              {data.facilitatorProfileImage ? (
                <Flex mt={8} flexDirection="column" flex={1}>
                  <Typography variant="type10" color="shade1" weight="600">
                    Feature Image
                  </Typography>
                  <Image
                    mt="1"
                    borderRadius="10px"
                    src={data.facilitatorProfileImage?.medium}
                  />
                </Flex>
              ) : null}
              <Flex mt={8} flex={1}>
                <AppButton variant="small" onClick={onViewUserProfile}>
                  View User Profile
                </AppButton>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          mt={3}
          flexDir="column"
          border="1px solid #ddd"
          height="100%"
          width={400}
          opacity={isSuspended ? 0.5 : 1}
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid #ddd"
            px={4}
            py={4}
          >
            <Typography variant="type8" color="shade5" weight="600">
              AGENT APPLICATION
            </Typography>
            {!isSuspended && (
              <Label
                typographyProps={{
                  variant: 'type11',
                  color: 'secondary',
                  style: {
                    height: 10,
                  },
                }}
                color={
                  isApproved ? 'special5' : isDenied ? 'special2' : 'special6'
                }
              >
                {data.status}
              </Label>
            )}
          </Flex>
          <Flex p={4} flexDirection="column">
            <Flex flexDirection="column" flex={1}>
              <Typography variant="type10" color="shade1" weight="600">
                DATE APPLIED
              </Typography>
              <Typography variant="type8" color="shade5">
                {format(new Date(data.dateApplied), 'MM/dd/y kk:mm:ss a')}
              </Typography>
            </Flex>
            {!isPending && !isSuspended ? (
              <>
                <Flex mt={8} flexDirection="column" flex={1}>
                  <Typography variant="type10" color="shade1" weight="600">
                    STATUS
                  </Typography>
                  <Typography variant="type8" color="shade5">
                    {data.status}
                  </Typography>
                </Flex>
                <Flex mt={8} flexDirection="column" flex={1}>
                  <Typography variant="type10" color="shade1" weight="600">
                    ACCEPTED BY
                  </Typography>
                  <Typography variant="type8" color="shade5">
                    {data.applicationRespondedBy?.username || '-'}
                  </Typography>
                </Flex>
                <Flex mt={8} flexDirection="column" flex={1}>
                  <Typography variant="type10" color="shade1" weight="600">
                    DATE ACCEPTED
                  </Typography>
                  <Typography variant="type8" color="shade5">
                    {data.dateAccepted
                      ? format(
                          new Date(data.dateAccepted),
                          'MM/dd/y kk:mm:ss a'
                        )
                      : '-'}
                  </Typography>
                </Flex>
              </>
            ) : (
              <Flex mt={8} flex={1} justifyContent="space-between">
                <Flex mr={2} flex={1}>
                  <AppButton
                    disabled={isSuspended}
                    variant="small"
                    color="special7"
                    transparentBorder
                    fullwidth
                    onClick={() => {
                      doRespond({
                        status: 'REJECTED',
                      });
                    }}
                  >
                    Decline
                  </AppButton>
                </Flex>
                <Flex flex={1}>
                  <AppButton
                    disabled={isSuspended}
                    variant="small"
                    fullwidth
                    onClick={() => {
                      doRespond({
                        status: 'APPROVED',
                      });
                    }}
                  >
                    Accept
                  </AppButton>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </HStack>
    </Flex>
  );
};

export default AgentFullDetailsView;
