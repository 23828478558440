import api from 'api/instance';
import { Broker } from 'types/Broker';

const URL = `${process.env.REACT_APP_API_URL}/admin/mortgage`;
const COMPANY_LIST_URL = `${process.env.REACT_APP_API_URL}/mortgage/company`;

export type GetAllBrokersResponse = {
  data: Broker[];
  meta: {
    count: number;
    page: number;
    limit: number;
  };
};

const getAll = async (payload?: string): Promise<GetAllBrokersResponse> => {
  const { data } = await api.get<GetAllBrokersResponse>(
    `${URL}/broker/${payload}`
  );
  return data;
};

type BrokerNewPayload = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  phoneCode: string;
  countryCode: string;
  company: string;
};

const doCreate = async (fields: BrokerNewPayload): Promise<void> => {
  const { data } = await api.post(`${URL}/broker`, fields);
  return data;
};

type GetAllCompaniesAPIResponse = {
  data: {
    companies: string[];
  };
};

const getAllCompanies = async (): Promise<string[]> => {
  const { data } = await api.get<GetAllCompaniesAPIResponse>(
    `${COMPANY_LIST_URL}`
  );
  return data.data.companies;
};

export default {
  getAll,
  doCreate,
  getAllCompanies,
};
