import locations from 'constants/locations';
import { AgentDetails } from 'types/Agent';
import { getOrdinal } from 'utils/string/getOrdinal';

import { EditForm } from './AgentDetailsEdit.props';

export const SERVICES: { key: string; label: string }[] = [
  {
    key: 'residential sales',
    label: 'Residential Sales',
  },
  {
    key: 'rural sales',
    label: 'Rural Sales',
  },
  {
    key: 'commercial sales',
    label: 'Commercial Sales',
  },
  {
    key: 'apartment sales',
    label: 'Apartment Sales',
  },
  {
    key: 'lifestyle sales',
    label: 'Lifestyle Sales',
  },
];

export const transformUserDetailsToForm = (data: AgentDetails): EditForm => ({
  facilitatorProfileImage: data.facilitatorProfileImage?.medium,
  about: data.about,
  company: data.company,
  companyLocation: data.companyLocation,
  licenseNo: data.licenseNumber,
  dateApplied: data.dateApplied,
  region: data.region,
  tradingAs: data.tradingAs,
  specialities: data.speciality.map((s) => s.value),
});

export const toUpdatePayload = (
  values: EditForm,
  facilitatorProfileImage?: string
): EditForm => {
  const obj = {
    about: values.about,
    company: values.company,
    licenseNo: values.licenseNo,
    dateApplied: values.dateApplied,
    region: values.region,
    tradingAs: values.tradingAs,
    specialities: values.specialities,
    companyLocation: values.companyLocation,
  };

  return facilitatorProfileImage ? { ...obj, facilitatorProfileImage } : obj;
};

export const getOrder = (value: string, specialities: string[]): string => {
  const i = specialities.findIndex((s) => s === value);

  if (i === -1) return '';
  else return getOrdinal(i + 1);
};

export const getRegionSelect = (): {
  value: string;
  label: string;
}[] =>
  locations.map(({ region }) => ({
    label: region,
    value: region.toLowerCase(),
  }));
