import * as Yup from 'yup';

export const Schema = Yup.object().shape({
  firstName: Yup.string().required('This field is required.'),
  lastName: Yup.string().required('This field is required.'),
  phone: Yup.string().required('This field is required.'),
  email: Yup.string()
    .email('Invalid email address.')
    .required('This field is required.'),
  company: Yup.string().required('This field is required.'),
});
